import React, { Fragment } from "react";
import Helmet from "react-helmet";
import PageHeader from "../components/PageHeader";
import Layout from "../components/Layout";

import Gallery from "../components/Gallery";

export const OurWorkSingleTemplate = ({
  title,
  carousel,
  masonry,
  splatter
}) => (
  <Fragment>
    <PageHeader title={title} paintColor={"purple"} />
    <div className="container">
      {masonry ? (
        <section
          className="section"
          style={{
            background: `url(${
              splatter.edges[0].node.childImageSharp.fluid.src
            }) no-repeat`,
            backgroundSize: `initial`,
            backgroundPosition: `center bottom`
            // backgroundAttachment: `fixed`
          }}
        >
          <div className="columns is-multiline">
            {/* <div className="masonry-layout">
              {masonry.map((img, i) => (
                <div key={i} className="masonry-layout__panel">
                  <div className="masonry-layout__panel-content">
                    <img src={img.image.childImageSharp.fluid.src} />
                  </div>
                </div>
              ))}
            </div> */}

            {masonry.map((img, i) => {
              return i === 0 || i === 1 || i % 5 === 0 || (i - 1) % 5 === 0 ? (
                <div key={i} className="column is-6">
                  <div
                    className="masonry-img"
                    style={{
                      background: `url(${img.image.childImageSharp.fluid.src})`,
                      backgroundSize: `cover`,
                      backgroundPosition: `center`,
                      height: `25rem`
                    }}
                  >
                    {/* <img src={img.image.childImageSharp.fluid.src} /> */}
                  </div>
                </div>
              ) : (
                <div key={i} className="column is-4">
                  <div
                    className="masonry-img"
                    style={{
                      background: `url(${img.image.childImageSharp.fluid.src})`,
                      backgroundSize: `cover`,
                      backgroundPosition: `center`,
                      height: `25rem`
                    }}
                  >
                    {/* <img src={img.image.childImageSharp.fluid.src} /> */}
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      ) : null}

      <div className="columns">
        <div className="column is-12">
          {carousel ? (
            <section className="section">
              <div className="has-text-centered">
                <h3 className="is-size-2 title-underline">Related Projects</h3>
              </div>
              <Gallery slides={carousel} />
            </section>
          ) : null}
        </div>
      </div>
    </div>
  </Fragment>
);

const OurWorkSinglePage = ({ data }) => {
  const { markdownRemark: post, allFile: edges } = data;

  return (
    <Layout>
      <OurWorkSingleTemplate
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        masonry={post.frontmatter.masonry}
        carousel={post.frontmatter.carousel}
        splatter={edges}
      />
    </Layout>
  );
};

export default OurWorkSinglePage;

export const pageQuery = graphql`
  query OurWorkSingleByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        masonry {
          image {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        carousel {
          heading
          subHeading
          linkText
          linksTo
          galleryImgs {
            image {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    allFile(
      filter: {
        relativePath: {
          eq: "bigstock-Colorful-Paint-Splash-Isolated-241498042.png"
        }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }
  }
`;
